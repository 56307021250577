import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import { EMPTY_OBJECT } from '@/constants';

interface GetSizeOptions {
  initialWidth: number;
  initialHeight: number;
  width?: number;
  isAutoSize?: boolean;
}

const getSize = (options: GetSizeOptions) => {
  const {
    initialWidth,
    initialHeight,
    width = initialWidth,
    isAutoSize,
  } = options;

  if (isAutoSize) {
    return {
      width: 'auto',
      height: 'auto',
      viewBox: `0 0 ${initialWidth} ${initialHeight}`,
    };
  }

  const newHeight = (initialHeight * width) / initialWidth;

  return {
    width,
    height: newHeight,
    viewBox: `0 0 ${initialWidth} ${initialHeight}`,
  };
};

type Props = ChubbsProps & {
  initialHeight: number;
  initialWidth: number;
  dataQa?: string;
};

export const ChubbsWrapper: FC<Props> = memo((props) => {
  const {
    initialHeight,
    initialWidth,
    children,
    width = initialWidth,
    height,
    className,
    dataQa,
    isAutoSize,
  } = props;

  const sizeOptions = getSize({
    initialWidth,
    initialHeight,
    width,
    isAutoSize,
  });

  const heightStyle = height
    ? { height: `${height}px` }
    : EMPTY_OBJECT;

  return (
    <div
      style={{
        ...heightStyle,
        overflow: 'hidden',
      }}
      className={className}
      data-qa={dataQa}
    >
      <svg
        width={sizeOptions.width}
        height={sizeOptions.height}
        viewBox={sizeOptions.viewBox}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        {children}
      </svg>
    </div>
  );
});
