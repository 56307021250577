import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import {
  ChubbsWrapper,
} from '@/components/ui/Chubbs/ChubbsWrapper';
import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsUndefinedDark: FC<ChubbsProps> = memo((props) => (
  <ChubbsWrapper initialHeight={193} initialWidth={118} {...props}>
    <g clipPath="url(#clip0_19425_3759)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.5999 39.23C70.7499 39.64 85.9599 49.98 89.5999 54.03C89.5999 54.03 95.0099 41.83 95.1899 41.44C96.5799 38.35 92.7699 32.01 99.9899 28.63C103.2 27.12 105.2 29.86 102.84 32.04C102.481 32.4211 102.04 32.7153 101.55 32.9L104.74 32.78C107.74 32.86 117.5 34.32 117.4 36.78C117.33 38.54 116.32 38.39 116.1 38.52C116.269 38.6169 116.413 38.7506 116.523 38.9112C116.633 39.0718 116.705 39.2551 116.734 39.4475C116.762 39.6399 116.747 39.8362 116.69 40.022C116.632 40.2077 116.533 40.378 116.4 40.52C115.35 41.81 113.78 41.04 114.4 41.42C115.71 42.19 114.57 44.66 112.77 44.25C107.87 43.11 105.88 44.56 104.94 46.38C100.81 54.38 97.9399 64.38 94.9399 67.38C87.9899 74.2 80.7899 64.81 71.9399 48.62C68.8699 42.99 65.3099 47.17 69.2099 42.09C69.3699 41.85 70.5999 39.23 70.5999 39.23Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M70.5999 39.23C70.7499 39.64 85.9599 49.98 89.5999 54.03C89.5999 54.03 95.0099 41.83 95.1899 41.44C96.5799 38.35 92.7699 32.01 99.9899 28.63C103.2 27.12 105.2 29.86 102.84 32.04C102.481 32.4211 102.04 32.7153 101.55 32.9L104.74 32.78C107.74 32.86 117.5 34.32 117.4 36.78C117.33 38.54 116.32 38.39 116.1 38.52C116.269 38.6169 116.413 38.7506 116.523 38.9112C116.633 39.0718 116.705 39.2551 116.734 39.4475C116.762 39.6399 116.747 39.8362 116.69 40.022C116.632 40.2077 116.533 40.378 116.4 40.52C115.35 41.81 113.78 41.04 114.4 41.42C115.71 42.19 114.57 44.66 112.77 44.25C107.87 43.11 105.88 44.56 104.94 46.38C100.81 54.38 97.9399 64.38 94.9399 67.38C87.9899 74.2 80.7899 64.81 71.9399 48.62C68.8699 42.99 65.3099 47.17 69.2099 42.09C69.3699 41.85 70.5999 39.23 70.5999 39.23Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.8701 42.1399L78.2401 45.2999C80.2401 54.5799 81.6901 56.8799 83.2401 65.9699C81.2401 64.3099 81.5301 68.8199 79.5301 67.1599L74.8701 42.1399Z"
        fill="#191A1F"
      />
      <path
        d="M74.8701 42.1399L78.2401 45.2999C80.2401 54.5799 81.6901 56.8799 83.2401 65.9699C81.2401 64.3099 81.5301 68.8199 79.5301 67.1599L74.8701 42.1399Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.9999 41.0899C40.4799 44.3399 31.1499 50.9999 28.3999 53.9999C28.3999 53.9999 22.9899 41.7999 22.8099 41.4099C21.4199 38.3499 25.2299 31.9999 17.9999 28.6299C14.7999 27.1199 12.7999 29.8599 15.1599 31.9999C15.5188 32.381 15.96 32.6752 16.4499 32.8599L13.2599 32.7399C10.2599 32.8199 0.499884 34.2799 0.599884 36.7399C0.669884 38.4999 1.67989 38.3499 1.90989 38.4799C1.74045 38.5759 1.59493 38.7089 1.48423 38.8691C1.37353 39.0293 1.30054 39.2125 1.27071 39.4049C1.24089 39.5973 1.25501 39.794 1.31202 39.9802C1.36903 40.1664 1.46744 40.3372 1.59988 40.4799C2.64988 41.7699 4.21988 40.9999 3.59988 41.3799C2.28988 42.1499 3.42989 44.6199 5.22989 44.2099C10.1299 43.0699 12.1199 44.5199 13.0599 46.3399C17.1899 54.3399 20.0599 64.3399 23.0599 67.3399C27.2799 71.4799 31.0599 68.8399 36.2899 64.2399C34.7642 70.0294 34.1502 76.0213 34.4699 81.9999H85.6499C84.9199 58.9999 74.7299 54.2299 74.7299 31.7299C74.7299 23.1799 74.7299 23.3199 74.7299 14.7299C74.7299 -3.57006 45.9399 -4.27006 45.9699 14.7299V33.1899C45.9849 35.8539 45.6589 38.5088 44.9999 41.0899Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M44.9999 41.0899C40.4799 44.3399 31.1499 50.9999 28.3999 53.9999C28.3999 53.9999 22.9899 41.7999 22.8099 41.4099C21.4199 38.3499 25.2299 31.9999 17.9999 28.6299C14.7999 27.1199 12.7999 29.8599 15.1599 31.9999C15.5188 32.381 15.96 32.6752 16.4499 32.8599L13.2599 32.7399C10.2599 32.8199 0.499884 34.2799 0.599884 36.7399C0.669884 38.4999 1.67989 38.3499 1.90989 38.4799C1.74045 38.5759 1.59493 38.7089 1.48423 38.8691C1.37353 39.0293 1.30054 39.2125 1.27071 39.4049C1.24089 39.5973 1.25501 39.794 1.31202 39.9802C1.36903 40.1663 1.46744 40.3372 1.59988 40.4799C2.64988 41.7699 4.21988 40.9999 3.59988 41.3799C2.28988 42.1499 3.42989 44.6199 5.22989 44.2099C10.1299 43.0699 12.1199 44.5199 13.0599 46.3399C17.1899 54.3399 20.0599 64.3399 23.0599 67.3399C27.2799 71.4799 31.0599 68.8399 36.2899 64.2399C34.7642 70.0294 34.1502 76.0213 34.4699 81.9999H85.6499C84.9199 58.9999 74.7299 54.2299 74.7299 31.7299C74.7299 23.1799 74.7299 23.3199 74.7299 14.7299C74.7299 -3.57006 45.9399 -4.27006 45.9699 14.7299V33.1899C45.9849 35.8539 45.6589 38.5088 44.9999 41.0899V41.0899Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.5901 53.9999C89.4101 54.4099 87.8602 57.7999 87.4102 58.8099C88.0233 57.3036 88.3129 55.6853 88.2601 54.0599C88.2601 54.0599 88.1301 52.6499 88.2601 52.8399C88.5689 53.3444 88.9273 53.8168 89.3302 54.2499C89.3402 54.1599 89.5501 53.9399 89.5901 53.9999Z"
        fill="#191A1F"
      />
      <path
        d="M89.5901 53.9999C89.4101 54.4099 87.8602 57.7999 87.4102 58.8099C88.0233 57.3036 88.3129 55.6853 88.2601 54.0599C88.2601 54.0599 88.1301 52.6499 88.2601 52.8399C88.5689 53.3444 88.9273 53.8168 89.3302 54.2499C89.3402 54.1599 89.5501 53.9399 89.5901 53.9999Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4102 53.9999C28.5902 54.4099 30.1401 57.7999 30.5901 58.8099C29.977 57.3036 29.6874 55.6853 29.7402 54.0599C29.7402 54.0599 29.8702 52.6499 29.7402 52.8399C29.4314 53.3444 29.073 53.8168 28.6702 54.2499C28.6702 54.2499 28.4502 53.9399 28.4102 53.9999Z"
        fill="#191A1F"
      />
      <path
        d="M28.4102 53.9999C28.5902 54.4099 30.1401 57.7999 30.5901 58.8099C29.977 57.3036 29.6874 55.6853 29.7402 54.0599C29.7402 54.0599 29.8702 52.6499 29.7402 52.8399C29.4314 53.3444 29.073 53.8168 28.6702 54.2499C28.6702 54.2499 28.4502 53.9399 28.4102 53.9999Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.4798 175.37H59.4799C59.3125 175.377 59.1539 175.447 59.0354 175.566C58.917 175.684 58.8472 175.843 58.8398 176.01L57.8398 190.17H91.4198C92.0498 184.24 87.6998 182.44 82.5798 179.9C80.6398 178.43 80.4798 175.37 80.4798 175.37Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M80.4798 175.37H59.4799C59.3125 175.377 59.1539 175.447 59.0354 175.566C58.917 175.684 58.8472 175.843 58.8398 176.01L57.8398 190.17H91.4198C92.0498 184.24 87.6998 182.44 82.5798 179.9C80.6398 178.43 80.4798 175.37 80.4798 175.37Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.4599 187.9H57.4799C57.1264 187.9 56.8398 188.186 56.8398 188.54V191.57C56.8398 191.923 57.1264 192.21 57.4799 192.21H91.4599C91.8133 192.21 92.0999 191.923 92.0999 191.57V188.54C92.0999 188.186 91.8133 187.9 91.4599 187.9Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M91.4599 187.9H57.4799C57.1264 187.9 56.8398 188.186 56.8398 188.54V191.57C56.8398 191.923 57.1264 192.21 57.4799 192.21H91.4599C91.8133 192.21 92.0999 191.923 92.0999 191.57V188.54C92.0999 188.186 91.8133 187.9 91.4599 187.9Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.8002 175.37H38.8002C38.6328 175.377 38.4742 175.447 38.3558 175.566C38.2373 175.684 38.1675 175.843 38.1602 176.01L37.1602 190.17H70.7402C71.3702 184.24 67.0202 182.44 61.9002 179.9C60.0002 178.43 59.8002 175.37 59.8002 175.37Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M59.8002 175.37H38.8002C38.6328 175.377 38.4742 175.447 38.3558 175.566C38.2373 175.684 38.1675 175.843 38.1602 176.01L37.1602 190.17H70.7402C71.3702 184.24 67.0202 182.44 61.9002 179.9C60.0002 178.43 59.8002 175.37 59.8002 175.37Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.7901 187.9H36.8002C36.4467 187.9 36.1602 188.186 36.1602 188.54V191.57C36.1602 191.923 36.4467 192.21 36.8002 192.21H70.7901C71.1436 192.21 71.4301 191.923 71.4301 191.57V188.54C71.4301 188.186 71.1436 187.9 70.7901 187.9Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M70.7901 187.9H36.8002C36.4467 187.9 36.1602 188.186 36.1602 188.54V191.57C36.1602 191.923 36.4467 192.21 36.8002 192.21H70.7901C71.1436 192.21 71.4301 191.923 71.4301 191.57V188.54C71.4301 188.186 71.1436 187.9 70.7901 187.9Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.7101 14.75C74.7101 23.3 74.7101 23.16 74.7101 31.75C74.7051 34.2629 74.8487 36.774 75.1401 39.27H45.3901C45.7805 37.2938 45.9781 35.2843 45.9801 33.27V14.81C45.9201 -4.20003 74.6801 -3.55003 74.7101 14.75Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M74.7101 14.75C74.7101 23.3 74.7101 23.16 74.7101 31.75C74.7051 34.2629 74.8487 36.774 75.1401 39.27H45.3901C45.7805 37.2938 45.9781 35.2843 45.9801 33.27V14.81C45.9201 -4.20003 74.6801 -3.55003 74.7101 14.75Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 13.2301C47.43 -3.63994 73.11 -3.08994 74.63 13.2301H46Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M46 13.2301C47.43 -3.63994 73.11 -3.08994 74.63 13.2301H46Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.56 81.72C34.5451 81.9264 34.5451 82.1336 34.56 82.34C34.38 92.01 37.5 97.34 37.47 106.17C37.47 125.32 36.9 174.55 36.95 175.55H81.7C81.7 175.55 82.1 125.44 81.82 106.66C81.68 96.66 84.58 93.8 85.66 82.45C85.675 82.2069 85.675 81.9631 85.66 81.72H34.56Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M34.56 81.72C34.5451 81.9264 34.5451 82.1336 34.56 82.34C34.38 92.01 37.5 97.34 37.47 106.17C37.47 125.32 36.9 174.55 36.95 175.55H81.7C81.7 175.55 82.1 125.44 81.82 106.66C81.68 96.66 84.58 93.8 85.66 82.45C85.675 82.2069 85.675 81.9631 85.66 81.72H34.56Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.4303 24.7101C73.1017 24.71 72.7804 24.613 72.5067 24.4312C72.2331 24.2494 72.0191 23.9909 71.8916 23.6881C71.7641 23.3853 71.7288 23.0516 71.79 22.7287C71.8513 22.4059 72.0064 22.1084 72.236 21.8733C72.4656 21.6382 72.7594 21.4761 73.0806 21.4072C73.4019 21.3383 73.7364 21.3658 74.0421 21.486C74.3479 21.6063 74.6114 21.8142 74.7996 22.0835C74.9878 22.3528 75.0924 22.6716 75.1003 23.0001C75.1056 23.2228 75.0663 23.4442 74.9848 23.6515C74.9033 23.8587 74.7811 24.0476 74.6255 24.2069C74.4699 24.3663 74.284 24.4929 74.0787 24.5793C73.8735 24.6657 73.653 24.7102 73.4303 24.7101Z"
        fill="white"
      />
      <path
        d="M73.4303 24.7101C73.1017 24.71 72.7804 24.613 72.5067 24.4312C72.2331 24.2494 72.0191 23.9909 71.8916 23.6881C71.7641 23.3853 71.7288 23.0516 71.79 22.7287C71.8513 22.4059 72.0064 22.1084 72.236 21.8733C72.4656 21.6382 72.7594 21.4761 73.0806 21.4072C73.4019 21.3383 73.7364 21.3658 74.0421 21.486C74.3479 21.6063 74.6114 21.8142 74.7996 22.0835C74.9878 22.3528 75.0924 22.6716 75.1003 23.0001C75.1056 23.2228 75.0663 23.4442 74.9848 23.6515C74.9033 23.8587 74.7811 24.0476 74.6255 24.2069C74.4699 24.3663 74.284 24.4929 74.0787 24.5793C73.8735 24.6657 73.653 24.7102 73.4303 24.7101V24.7101Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.4097 21.3701C59.7417 21.3702 60.0661 21.4692 60.3416 21.6546C60.617 21.8399 60.8309 22.1031 60.9561 22.4106C61.0812 22.7181 61.1119 23.0559 61.0441 23.3809C60.9764 23.7059 60.8133 24.0033 60.5757 24.2352C60.3382 24.4671 60.0369 24.6229 59.7103 24.6828C59.3838 24.7426 59.0468 24.7038 58.7425 24.5712C58.4381 24.4387 58.1801 24.2184 58.0015 23.9386C57.8229 23.6587 57.7318 23.332 57.7397 23.0001C57.7502 22.5642 57.9307 22.1496 58.2428 21.845C58.5548 21.5404 58.9737 21.37 59.4097 21.3701Z"
        fill="white"
      />
      <path
        d="M59.4097 21.3701C59.7417 21.3702 60.0661 21.4692 60.3416 21.6546C60.617 21.8399 60.8309 22.1031 60.9561 22.4106C61.0812 22.7181 61.1119 23.0559 61.0441 23.3809C60.9764 23.7059 60.8133 24.0033 60.5757 24.2352C60.3382 24.4671 60.0369 24.6229 59.7103 24.6828C59.3838 24.7426 59.0468 24.7038 58.7425 24.5712C58.4381 24.4387 58.1801 24.2184 58.0015 23.9386C57.8229 23.6587 57.7318 23.332 57.7397 23.0001C57.7502 22.5642 57.9307 22.1496 58.2428 21.845C58.5548 21.5404 58.9737 21.37 59.4097 21.3701Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60 106.87C60 102.78 66.72 103.16 69.17 97.3501"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.8101 16.56C73.4689 16.4636 74.1416 16.5682 74.7401 16.86"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M60.1102 16.56C59.4513 16.4636 58.7787 16.5682 58.1802 16.86"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.2197 36.6399H74.7197C75.2502 36.6399 75.7589 36.8506 76.1339 37.2257C76.509 37.6008 76.7197 38.1095 76.7197 38.6399C76.7197 39.1703 76.509 39.679 76.1339 40.0541C75.7589 40.4292 75.2502 40.6399 74.7197 40.6399H46.2197C45.6893 40.6399 45.1806 40.4292 44.8055 40.0541C44.4304 39.679 44.2197 39.1703 44.2197 38.6399C44.2197 38.1095 44.4304 37.6008 44.8055 37.2257C45.1806 36.8506 45.6893 36.6399 46.2197 36.6399Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M46.2197 36.6399H74.7197C75.2502 36.6399 75.7589 36.8506 76.1339 37.2257C76.509 37.6008 76.7197 38.1095 76.7197 38.6399C76.7197 39.1703 76.509 39.679 76.1339 40.0541C75.7589 40.4292 75.2502 40.6399 74.7197 40.6399H46.2197C45.6893 40.6399 45.1806 40.4292 44.8055 40.0541C44.4304 39.679 44.2197 39.1703 44.2197 38.6399C44.2197 38.1095 44.4304 37.6008 44.8055 37.2257C45.1806 36.8506 45.6893 36.6399 46.2197 36.6399Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M45.9502 13.0901H89.7902"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.1198 40.5901L75.5498 42.3601C69.9915 41.4609 64.3852 40.8899 58.7598 40.6501L75.1198 40.5901Z"
        fill="#191A1F"
      />
      <path
        d="M75.1198 40.5901L75.5498 42.3601C69.9915 41.4609 64.3852 40.8899 58.7598 40.6501L75.1198 40.5901Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.6601 175.51L81.0901 177.28C75.5353 176.382 69.9321 175.814 64.3101 175.58L80.6601 175.51Z"
        fill="#191A1F"
      />
      <path
        d="M80.6601 175.51L81.0901 177.28C75.5353 176.382 69.9321 175.814 64.3101 175.58L80.6601 175.51Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.7001 175.51L60.1301 177.28C54.5753 176.382 48.9721 175.814 43.3501 175.58L59.7001 175.51Z"
        fill="#191A1F"
      />
      <path
        d="M59.7001 175.51L60.1301 177.28C54.5753 176.382 48.9721 175.814 43.3501 175.58L59.7001 175.51Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.2601 13L74.7001 14.77C69.1419 13.8708 63.5355 13.2998 57.9102 13.06L74.2601 13Z"
        fill="#191A1F"
      />
      <path
        d="M74.2601 13L74.7001 14.77C69.1419 13.8708 63.5355 13.2998 57.9102 13.06L74.2601 13Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.2798 22.8701C73.3302 22.8701 73.3786 22.8901 73.4142 22.9258C73.4498 22.9614 73.4698 23.0097 73.4698 23.0601C73.4713 23.0854 73.4674 23.1108 73.4583 23.1345C73.4493 23.1582 73.4354 23.1797 73.4174 23.1977C73.3995 23.2156 73.3779 23.2296 73.3542 23.2386C73.3305 23.2476 73.3052 23.2516 73.2798 23.2501C73.2295 23.2501 73.1811 23.2301 73.1455 23.1945C73.1099 23.1588 73.0898 23.1105 73.0898 23.0601C73.0923 23.0105 73.1131 22.9636 73.1482 22.9285C73.1833 22.8933 73.2302 22.8725 73.2798 22.8701Z"
        fill="#191A1F"
      />
      <path
        d="M73.2798 22.8701C73.3302 22.8701 73.3786 22.8901 73.4142 22.9258C73.4498 22.9614 73.4698 23.0097 73.4698 23.0601C73.4713 23.0854 73.4674 23.1108 73.4583 23.1345C73.4493 23.1582 73.4354 23.1797 73.4174 23.1977C73.3995 23.2156 73.3779 23.2296 73.3542 23.2386C73.3305 23.2476 73.3052 23.2516 73.2798 23.2501C73.2295 23.2501 73.1811 23.2301 73.1455 23.1945C73.1099 23.1588 73.0898 23.1105 73.0898 23.0601C73.0923 23.0105 73.1131 22.9636 73.1482 22.9285C73.1833 22.8933 73.2302 22.8725 73.2798 22.8701V22.8701Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.44 23.2699C59.3904 23.2675 59.3435 23.2467 59.3083 23.2116C59.2732 23.1765 59.2524 23.1295 59.25 23.0799C59.25 23.0295 59.27 22.9812 59.3056 22.9456C59.3413 22.9099 59.3896 22.8899 59.44 22.8899C59.4658 22.8886 59.4916 22.8925 59.5158 22.9014C59.54 22.9103 59.5621 22.9241 59.5809 22.9419C59.5996 22.9597 59.6145 22.9811 59.6246 23.0048C59.6348 23.0285 59.64 23.0541 59.64 23.0799C59.6374 23.1312 59.6152 23.1795 59.578 23.2149C59.5408 23.2503 59.4914 23.27 59.44 23.2699Z"
        fill="#191A1F"
      />
      <path
        d="M59.44 23.2699C59.3904 23.2675 59.3435 23.2467 59.3083 23.2116C59.2732 23.1765 59.2524 23.1295 59.25 23.0799C59.25 23.0295 59.27 22.9812 59.3056 22.9456C59.3413 22.9099 59.3896 22.8899 59.44 22.8899C59.4658 22.8886 59.4916 22.8925 59.5158 22.9014C59.54 22.9103 59.5621 22.9241 59.5809 22.9419C59.5996 22.9597 59.6145 22.9811 59.6246 23.0048C59.6348 23.0285 59.64 23.0541 59.64 23.0799C59.6374 23.1312 59.6152 23.1795 59.578 23.2149C59.5408 23.2503 59.4914 23.27 59.44 23.2699V23.2699Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.0303 175.55V106.74"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M116.29 38.5C114.326 37.6248 112.218 37.1164 110.07 37"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M1.70996 38.5C3.67445 37.6248 5.78249 37.1164 7.92996 37"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M114.59 41.39C114.02 41.17 113.45 40.49 109.88 40.49"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M3.41016 41.39C3.98016 41.17 4.55016 40.49 8.12016 40.49"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M65.1099 28.78H69.3599"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M101.76 32.8C101.67 34.18 102.21 33.74 103.26 35.28C103.753 36.0221 104.093 36.855 104.26 37.73"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M16.2402 32.8C16.3302 34.18 15.7902 33.74 14.7402 35.28C14.2473 36.0221 13.9074 36.855 13.7402 37.73"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M35.48 64.86L42.94 57.95"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.2902 64.23L42.5602 58.3L35.4302 67.84L36.2902 64.23Z"
        fill="#191A1F"
      />
      <path
        d="M36.2902 64.23L42.5602 58.3L35.4302 67.84L36.2902 64.23Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19425_3759">
        <rect width="118" height="193" fill="white" />
      </clipPath>
    </defs>
  </ChubbsWrapper>
));
