import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import { Chubbs } from '@/components/ui/Chubbs/Chubbs';
import {
  ChubbsUndefinedLight,
} from '@/components/ui/Chubbs/ChubbsUndefined/ChubbsUndefined.light';
import {
  ChubbsUndefinedDark,
} from '@/components/ui/Chubbs/ChubbsUndefined/ChubbsUndefined.dark';

export const ChubbsUndefined: FC<ChubbsProps> = memo((props) => (
  <Chubbs
    LightChubbs={ChubbsUndefinedLight}
    DarkChubbs={ChubbsUndefinedDark}
    dataQa='chubbs-undefined'
    {...props}
  />
));
